import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccountMenuToggle, LazyContactsFloatingButton, LazyLanguageToggle, LazyLogo, LazyMerchantToolbar, LazyWorkspaceDropdown, LazyThemeToggle, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AccountMenuToggle", LazyAccountMenuToggle],
["ContactsFloatingButton", LazyContactsFloatingButton],
["LanguageToggle", LazyLanguageToggle],
["Logo", LazyLogo],
["MerchantToolbar", LazyMerchantToolbar],
["WorkspaceDropdown", LazyWorkspaceDropdown],
["ThemeToggle", LazyThemeToggle],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
