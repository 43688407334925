<script setup lang="ts">
</script>

<template>
  <NuxtLink to="/"><img src="/img/logos/brand_logo.svg" alt="logo" class="logo"></NuxtLink>
</template>

<style>
.logo {
  flex-shrink: 0;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  filter: brightness(1.5);
}
</style>
